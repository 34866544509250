import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IGenericResponse } from '../interfaces/generic.interface';
import { IMaterialityMatrixTheme } from '../interfaces/materiality-matrix-theme.interface';
import { IMaterialityStatus } from '../interfaces/materiality.interface';
import { IOrganizationContext } from '../interfaces/organization-context.interface';
import {
  IMaterialityPotentialThemes,
  IPotentialTheme,
  IPotentialThemesEvaluate
} from '../interfaces/potential-themes.interface';
import {
  IStakeholder,
  IStakeholderGroup
} from '../interfaces/stakeholder.interface';
import { IMaterialityRelevance } from './../interfaces/stakeholder.interface';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialityService {
  apiUrl = `${environment.apiUrl}/materiality`;
  handleErrorCustom = { handleErrorCustom: 'true' };

  materialityStatusData = signal<IMaterialityStatus[] | null>(null);

  constructor(
    private http: HttpClient,
    private customerService: CustomerService
  ) {}

  getMaterialityStatus(): Observable<IMaterialityStatus[]> {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http.get<IMaterialityStatus[]>(
      `${this.apiUrl}/status/${customerId}`
    );
  }

  //ORGANIZATION CONTEXT
  getOrganizationalContext(): Observable<IOrganizationContext> {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http.get<IOrganizationContext>(
      `${this.apiUrl}/organizationalContext/${customerId}`,
      {
        headers: this.handleErrorCustom
      }
    );
  }

  saveOrganizationalContext(
    data: IOrganizationContext
  ): Observable<IOrganizationContext> {
    return this.http.post<IOrganizationContext>(
      `${this.apiUrl}/organizationalContext/submit`,
      data
    );
  }

  saveDraftOrganizationalContext(
    data: IOrganizationContext
  ): Observable<IOrganizationContext> {
    return this.http.post<IOrganizationContext>(
      `${this.apiUrl}/organizationalContext/draft`,
      data
    );
  }

  //STAKEHOLDERGROUPS
  getStakeholderGroups(): Observable<IGenericResponse<IMaterialityRelevance>> {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http.get<IGenericResponse<IMaterialityRelevance>>(
      `${this.apiUrl}/stakeholderGroup/${customerId}`,
      {
        headers: this.handleErrorCustom
      }
    );
  }

  saveStakeholderGroups(
    data: IMaterialityRelevance
  ): Observable<IMaterialityRelevance> {
    return this.http.post<IMaterialityRelevance>(
      `${this.apiUrl}/stakeholderGroup/submit`,
      data
    );
  }

  saveDraftStakeholderGroups(
    data: IMaterialityRelevance
  ): Observable<IMaterialityRelevance> {
    return this.http.post<IMaterialityRelevance>(
      `${this.apiUrl}/stakeholderGroup/draft`,
      data
    );
  }

  createStakeholderGroup(
    data: IStakeholderGroup
  ): Observable<IStakeholderGroup> {
    return this.http.post<IStakeholderGroup>(
      `${environment.apiUrl}/stakeholderGroup`,
      { companyId: this.customerService.customerActive()?.id, ...data }
    );
  }

  deleteStakeholderGroup(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/stakeholderGroup/${id}`);
  }

  //POTENTIAL THEMES
  getPotentialThemes(): Observable<
    IGenericResponse<IMaterialityPotentialThemes<IPotentialTheme>>
  > {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http.get<
      IGenericResponse<IMaterialityPotentialThemes<IPotentialTheme>>
    >(`${this.apiUrl}/theme/${customerId}`, {
      headers: this.handleErrorCustom
    });
  }

  getGeneralThemes(): Observable<IGenericResponse<IPotentialTheme[]>> {
    return this.http.get<IGenericResponse<IPotentialTheme[]>>(
      `${environment.apiUrl}/theme`,
      {
        headers: this.handleErrorCustom
      }
    );
  }

  savePotentialThemes(
    data: IMaterialityPotentialThemes<IPotentialTheme>
  ): Observable<IMaterialityPotentialThemes<IPotentialTheme>> {
    return this.http.post<IMaterialityPotentialThemes<IPotentialTheme>>(
      `${this.apiUrl}/theme/submit`,
      data
    );
  }

  saveDraftPotentialThemes(
    data: IMaterialityPotentialThemes<IPotentialTheme>
  ): Observable<IMaterialityPotentialThemes<IPotentialTheme>> {
    return this.http.post<IMaterialityPotentialThemes<IPotentialTheme>>(
      `${this.apiUrl}/theme/draft`,
      data
    );
  }

  //RELEVANCE THEMES
  getMaterialityRelevance(): Observable<
    IGenericResponse<IMaterialityRelevance>
  > {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http.get<IGenericResponse<IMaterialityRelevance>>(
      `${this.apiUrl}/themeRelevance/${customerId}`,
      {
        headers: this.handleErrorCustom
      }
    );
  }

  getStakeholder(id: string): Observable<IGenericResponse<IStakeholder>> {
    return this.http.get<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/${id}`
    );
  }

  createStakeholder(data: IStakeholder): Observable<IStakeholder> {
    return this.http.post<IStakeholder>(
      `${environment.apiUrl}/stakeholder`,
      data
    );
  }

  updateStakeholder(data: IStakeholder): Observable<IStakeholder> {
    return this.http.put<IStakeholder>(
      `${environment.apiUrl}/stakeholder`,
      data
    );
  }

  deleteStakeholder(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/stakeholder/${id}`);
  }

  getSurveyByStakeholder(
    stakeholderId: string
  ): Observable<IGenericResponse<IStakeholder>> {
    return this.http.get<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/survey/${stakeholderId}`
    );
  }

  saveDraftSurveyByStakeholder(
    data: IStakeholder
  ): Observable<IGenericResponse<IStakeholder>> {
    return this.http.post<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/survey/draft`,
      data
    );
  }

  saveSurveyByStakeholder(
    data: IStakeholder
  ): Observable<IGenericResponse<IStakeholder>> {
    return this.http.post<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/survey/submit`,
      data
    );
  }

  getSurveyByToken(token: string): Observable<IGenericResponse<IStakeholder>> {
    return this.http.get<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/survey/token/${token}`
    );
  }

  saveDraftSurveyByToken(
    data: IStakeholder
  ): Observable<IGenericResponse<IStakeholder>> {
    return this.http.post<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/survey/token/draft`,
      data
    );
  }

  saveSurveyByToken(
    data: IStakeholder
  ): Observable<IGenericResponse<IStakeholder>> {
    return this.http.post<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/survey/token/submit`,
      data
    );
  }

  sendEmailStakeholderSurvey(
    stakeholderId: string
  ): Observable<IGenericResponse<IStakeholder>> {
    return this.http.put<IGenericResponse<IStakeholder>>(
      `${environment.apiUrl}/stakeholder/survey/sendEmail/${stakeholderId}`,
      {}
    );
  }

  saveMaterialityRelevance(
    data: IMaterialityRelevance
  ): Observable<IMaterialityRelevance> {
    return this.http.post<IMaterialityRelevance>(
      `${this.apiUrl}/themeRelevance/submit`,
      data
    );
  }

  // EVALUATE POTENTIAL THEMES
  getEvaluatePotentialThemes(): Observable<
    IGenericResponse<IMaterialityPotentialThemes<IPotentialThemesEvaluate>>
  > {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http.get<
      IGenericResponse<IMaterialityPotentialThemes<IPotentialThemesEvaluate>>
    >(`${this.apiUrl}/impactEvaluation/${customerId}`, {
      headers: this.handleErrorCustom
    });
  }

  saveEvaluatePotentialThemes(
    data: IMaterialityPotentialThemes<IPotentialThemesEvaluate>
  ): Observable<
    IGenericResponse<IMaterialityPotentialThemes<IPotentialThemesEvaluate>>
  > {
    return this.http.post<
      IGenericResponse<IMaterialityPotentialThemes<IPotentialThemesEvaluate>>
    >(`${this.apiUrl}/impactEvaluation/submit`, data);
  }

  saveDraftEvaluatePotentialThemes(
    data: IMaterialityPotentialThemes<IPotentialThemesEvaluate>
  ): Observable<
    IGenericResponse<IMaterialityPotentialThemes<IPotentialThemesEvaluate>>
  > {
    return this.http.post<
      IGenericResponse<IMaterialityPotentialThemes<IPotentialThemesEvaluate>>
    >(`${this.apiUrl}/impactEvaluation/draft`, data);
  }

  // MATERIALITY MATRIX
  getMaterialityMatrix(): Observable<
    IGenericResponse<IMaterialityPotentialThemes<IMaterialityMatrixTheme>>
  > {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http.get<
      IGenericResponse<IMaterialityPotentialThemes<IMaterialityMatrixTheme>>
    >(`${this.apiUrl}/matrix/${customerId}`, {
      headers: this.handleErrorCustom
    });
  }

  saveMaterialityMatrix(
    data: IMaterialityPotentialThemes<IMaterialityMatrixTheme>
  ): Observable<IMaterialityPotentialThemes<IMaterialityMatrixTheme>> {
    return this.http.post<IMaterialityPotentialThemes<IMaterialityMatrixTheme>>(
      `${this.apiUrl}/matrix/submit`,
      data
    );
  }
}
